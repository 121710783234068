import {mapGetters, mapActions} from "vuex";

import VueAdsPagination, {VueAdsPageButton} from 'vue-ads-pagination';
import cardMedia from '@/components/cards/card-media/index.vue'
import emptyData from '@/components/empty-data/index.vue'

export default {
  name: 'media',
  components: {
    VueAdsPagination,
    VueAdsPageButton,
    cardMedia,
    emptyData
  },
  data() {
    return {};
  },
  beforeUpdate() {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  },
  watch: {
    '$route.query.page': {
      immediate: true,
      handler: function (page) {
        if (page !== undefined) {
          this.fetchBlogList({skip: (this.$route.query.page - 1) * this.blogLimit})
        } else {
          this.$router.replace({name: 'blog', query: {page: 1}}).catch(() => {
            console.log();
          });
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      blogList: `blog/blogList`,
      isLoadingBlog: `blog/isLoadingBlog`,
      blogMeta: `blog/blogMeta`,
      blogLimit: `blog/blogLimit`,
    }),
    getPage() {
      return parseInt(this.$route.query.page) - 1;
    },
  },
  methods: {
    ...mapActions({
      fetchBlogList: `blog/BLOG_DATA_GET`,
    }),

    changePage(page) {
      this.$router.replace({name: 'blog', query: {page: parseInt(page) + 1}}).catch(() => {
        console.log()
      });
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    },
  },
};
